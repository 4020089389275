<template>
    <div>
        <div v-if="authUserPermission['stock-calc-recommend']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-6">
                                <h3 class="mb-0">Cek Rekomendasi Model</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="card-text">
                            <p class="fw-bold">Sebelum menggunakan kalkulator, dapat terlebih dahulu mengecek model inventory yang cocok dari pola demand lima tahun terakhir, rekomendasi berdasarkan kondisi berikut:
                            <ul>
                                <li>
                                    Jika material kategori fast moving dan terdapat data GI >10 selama 5 tahun terakhir: <strong>Model Min-Max</strong>
                                </li>
                                <li>
                                    Sebaliknya jika terdapat data GI <= 10 selama 5 tahun terakhir: <strong>Model Service Level </strong>
                                </li>
                            </ul>
                            </p>
                        </div>
                       

                        <div class="row mt-3">
                            <div class="col-4">
                                <label class="form-control-label"><b>Anak Perusahaan</b>
                                     <el-tooltip content="Anak perusahan dalam PI Grup" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip>  
                                </label>
                                <select class="form-control custom-select" v-model="anakPerusahaan" :disabled="loadingCalculate || loadingRefresh" @change="dependCompany">
                                    <option value="">Choose Company</option>
                                    <option v-for="company in companies" :key="'cmp'+company.code" :value="company.code" :label="company.code+' - '+company.name"></option>
                                </select>

                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Material Number</b>
                                     <el-tooltip content="Material Spare Part MRO" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip>  
                                </label><br>
                                <el-select v-model="materialNumber" filterable style="width:100%" placeholder="Material Number" :disabled="loadingCalculate || loadingRefresh">
                                    <el-option v-for="mn in materialNumbers" :key="mn" :value="mn" :label="mn"></el-option>
                                </el-select>
                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Gunakan Data TA</b>
                                    <el-tooltip content="Pilihan Mengikutsertakan Data Demand Turn Around pada Perhitungan" placement="top">
                                    <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label><br>
                                <el-select v-model="dataTa" style="width:100%" placeholder="Gunakan Data Ta" :disabled="loadingCalculate || loadingRefresh">
                                    <el-option label="Ya" value="True"></el-option>
                                    <el-option label="Tidak" value="False"></el-option>
                                </el-select>
                            </div>
                        </div>

                        <div class="text-center mt-5">
                            <input type="button" class="btn btn-danger" value="Check Recommendation" @click="calculate" :disabled="loadingCalculate || loadingRefresh || disabledCalculate">
                            <base-button  type="default" @click="resetParameter"  :disabled="disableDownload || loadingDownload">Reset</base-button>
                        </div>
                        <div v-if="!loadingCalculate && !!summary">
                            <div class="mt-5 text-center">
                                <h3><b>Summary</b></h3>
                                <div class="py-3" style="border: 1px solid #858585">
                                    <span>Rekomendasi model adalah <b>{{ summary }}</b>, karena terdapat <b>{{ summaryGi }}</b> data pengambilan material <b>{{ summaryMaterial }}</b>{{ summaryFast }} selama 5 tahun terakhir<br></span>   
                                </div>
                            </div>
                        </div>
                        <skeleton-loading v-else-if="loadingCalculate"></skeleton-loading>
                        <empty v-else></empty>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>

        
        <!-- MODAL Download -->
        <modal :show.sync="modalDownload" size="lg">
            <template slot="header">
                <h5 class="modal-title">Download</h5>
            </template>
            <template>
                <label for="">Type
                    <el-tooltip content="Top center" placement="top">
                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                    </el-tooltip> 
                </label>
                <select name="" id="" class="form-control" v-model="downloadType">
                    <option value="Leadtime">Leadtime</option>
                    <option value="Demand">Demand</option>
                </select><br>
                <button class="btn btn-primary btn-md mt-3" @click="download" :disabled="disableDownload || loadingDownload">
                    <span v-if="loadingDownload"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                    <span v-else>Download</span>
                </button>

            </template>
        </modal>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import axios from 'axios'
    import SkeletonLoading from '../../components/SkeletonLoading.vue';

    export default {
        components: { SkeletonLoading },  
        data() {
            return {          
                anakPerusahaan: '',
                materialNumber: '',
                dataTa:'',
                modalDetail: false,
                loadingCalculate:false,
                materialNumbers:[],
                modalDownload:false,
                downloadType:'',
                loadingDownload:false,
                loadingRefresh:false,
                summary:null,
                summaryMaterial: '',
                summaryGi:'',
                summaryFast:''
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
            disableDownload(){
                if(!!!this.materialNumber || !!!this.anakPerusahaan){
                    return true;
                }else{
                    return false;
                }
            },
            disabledCalculate(){
                if(!!!this.materialNumber || !!!this.anakPerusahaan || !!!this.dataTa){
                    return true;
                }else{
                    return false;
                }
            },
            companies(){
                let data = [
                    {
                        code:'PKG',
                        name:'PT Petrokimia Gresik'
                    },{
                        code:'PKC',
                        name:'PT Pupuk Kujang'
                    },{
                        code:'PKT',
                        name:'PT Pupuk Kalimantan Timur'
                    },{
                        code:'PIM',
                        name:'PT Pupuk Iskandar Muda'
                    },{
                        code:'PSP',
                        name:'PT Pupuk Sriwidjaja'
                    }]
                return data;
            }
        },
        watch:{
            'materialNumber': function(){
                this.dataTa = ''
            },
            'anakPerusahaan': function(){
                this.dataTa = ''
                this.materialNumber = ''
            },
            'summary': function(val){
                if(val != '' || val != null){
                    this.summaryMaterial = Number(this.materialNumber.split(";")[0])
                    if(val == 'Model Service Level'){
                        this.summaryGi = '<= 10'
                        this.summaryFast = ' '
                    }else{
                        this.summaryGi = '> 10'
                        this.summaryFast = ' fast moving'
                    }
                }
            }
        },
        methods: {
            async calculate() {
                this.loadingCalculate = true;

                let params = JSON.stringify(
                {
                    "Material Number":Number(this.materialNumber.split(";")[0]),
                    "Anak Perusahaan":this.anakPerusahaan,
                    "MSEG dengan TA": this.dataTa
                })
                var config = {
                    method: 'post',
                    url: 'https://api-fm.pupuk-indonesia.com/rekomendasi',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : params
                };
                await axios(config).then(response => this.summary = response.data["Message"])
                .catch(error => this.calculateError());

                this.loadingCalculate = false;
            },
            completeDataNotify(title) {
            	this.$notify({
					message: this.tt("please_complete_data", { title: title }),                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            async dependCompany(){
                var params =  JSON.stringify({
                                "Perusahaan" :this.anakPerusahaan
                            })
                var config = {
                    method: 'post',
                    url: 'https://api-fm.pupuk-indonesia.com/material-list',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : params
                };
                await axios(config).then(response =>  this.materialNumbers =  response.data["Material"]).catch(error => {
                    console.log(error)
                    // this.$notify({
                    //     message: 'Retrieved Data Company Failed',                  
                    //     type: 'danger',
                    //     verticalAlign: "bottom",
                    //     horizontalAlign: "left"
                    // });
                })
                .catch(() => this.materialNumbers = []);
            },
            
            handleDownload(){
                this.modalDownload = true
            },
            async download() {
                var url = ''
                if(this.downloadType == 'Demand'){
                    url = 'https://api-fm.pupuk-indonesia.com/csv-demand-mseg';
                }else if(this.downloadType == 'Leadtime'){
                    url = 'https://api-fm.pupuk-indonesia.com/csv-leadtime';
                }else{
                    this.completeDataNotify('Type of Download');
                    return;
                }
                this.loadingDownload = true;
                let params = JSON.stringify(
                {
                    "Material Number":Number(this.materialNumber.split(";")[0]),
                    "Anak Perusahaan":this.anakPerusahaan,
                })
                var config = {
                    method: 'post',
                    url: url,

                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    responseType: 'blob',
                    data : params
                };
                await axios(config).then(({ data }) => {

                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

                    const link = document.createElement('a');

                    link.href = downloadUrl;

                    link.setAttribute('download', 'file.csv'); //any other extension

                    document.body.appendChild(link);

                    link.click();

                    link.remove();

                })
                .catch(error => {
                    this.$notify({
                        message: 'Download Data is Failed',                  
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                });

                this.loadingDownload = false;
            },
            formatNumber(value) {
                let convert = value.toString();
                let splitValue  = convert.split('.');
                let withDot = splitValue[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                if(!!splitValue[1]){
                    return 'Rp. ' + withDot + ',' + splitValue[1]
                }else{
                    return 'Rp. ' + withDot
                }
            },
            resetParameter(){
                this.materialNumber = '' 
                this.anakPerusahaan = ''
                this.dataTa = ''
            },
            calculateError(){
                this.$notify({
					message: 'Calculate Data is Error',                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            }
            
        }   
    };
</script>

<style>
.el-tooltip__popper {
  max-width: 320px  !important;
  word-break: break-word;
}
</style>